import type { FromModel } from "@/__main__/data-model.mjs";
import createModel, { Any, Mapped, Optional } from "@/__main__/data-model.mjs";

export const EntryModel = createModel({
  data: {
    gameArticle: {
      sys: {
        id: String,
        firstPublishedAt: String,
      },
      contentfulMetadata: {
        tags: [{ id: String }],
      },
      content: {
        json: {
          // The typings in this object cannot be written in Blitz since its has recursive properties
          // This is handled in the Articles component with assigned typings
          nodeType: String,
          content: [Any],
        },
        links: {
          assets: {
            block: [
              {
                sys: {
                  id: String,
                },
                url: String,
                contentType: String,
                width: Number,
                height: Number,
              },
            ],
          },
        },
      },
      title: String,
      seoTitle: String,
      seoDescription: String,
      coverImage: {
        url: String,
      },
      author: {
        name: String,
      },
    },
  },
}).transform(
  {
    title: String,
    createdAt: Number,
    id: String,
    seoTitle: String,
    seoDescription: String,
    coverImageUrl: String,
    author: String,
    tags: [String],
    content: [Any],
    assets: Mapped({
      key: String, // Shares the same value as id
      value: {
        id: String, // Shares the same value as key
        url: String,
        contentType: String,
        width: Number,
        height: Number,
      },
    }),
  },
  (response) => {
    const { gameArticle } = response.data;
    return {
      title: gameArticle.title,
      author: gameArticle.author.name,
      coverImageUrl: gameArticle.coverImage.url,
      seoTitle: gameArticle.seoTitle,
      seoDescription: gameArticle.seoDescription,
      createdAt: new Date(gameArticle.sys.firstPublishedAt).getTime(),
      id: gameArticle.sys.id,
      tags: gameArticle.contentfulMetadata.tags.map((i) => i.id),
      content:
        "content" in gameArticle.content.json
          ? gameArticle.content.json.content
          : [],
      assets: gameArticle.content.links.assets.block.reduce(
        (acc, { sys, ...rest }) => {
          acc[sys.id] = {
            id: sys.id,
            ...rest,
          };
          return acc;
        },
        {},
      ),
    };
  },
);
export const RelatedEntriesModel = createModel({
  data: {
    gameArticleCollection: {
      items: [
        {
          sys: {
            id: String,
            firstPublishedAt: String,
          },
          author: {
            name: String,
          },
          title: String,
          excerpt: String,
          ctaText: String,
          coverImage: {
            url: String,
          },
          contentfulMetadata: {
            tags: [{ id: String }],
          },
        },
      ],
    },
  },
}).transform(
  [
    {
      id: String,
      title: String,
      excerpt: String,
      imageUrl: String,
      ctaText: String,
      tags: [String],
      createdAt: Number,
      author: String,
    },
  ],
  (response) =>
    response.data.gameArticleCollection.items.map((i) => ({
      id: i.sys.id,
      title: i.title,
      author: i.author.name,
      excerpt: i.excerpt,
      imageUrl: i.coverImage.url,
      ctaText: i.ctaText,
      tags: i.contentfulMetadata.tags.map((i) => i.id),
      createdAt: new Date(i.sys.firstPublishedAt).getTime(),
    })),
);
export const IntroModel = createModel({
  data: {
    introCollection: {
      items: [
        {
          title: String,
          description: String,
          backgroundImage: {
            url: String,
          },
        },
      ],
    },
  },
}).transform(
  Optional({ title: String, description: String, bgUrl: String }),
  ({
    data: {
      introCollection: { items },
    },
  }) => {
    const item = items[0];
    if (!item) return undefined;
    return {
      title: item.title,
      description: item.description,
      bgUrl: item.backgroundImage.url,
    };
  },
);

export type Entry = FromModel<typeof EntryModel>;
export type RelatedEntries = FromModel<typeof RelatedEntriesModel>;
export type Intro = FromModel<typeof IntroModel>;
