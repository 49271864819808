import {
  __ONLY_WRITE_STATE_FROM_ACTIONS as writeState,
  isPersistent,
  readState,
} from "@/__main__/app-state.mjs";
import { postData } from "@/__main__/get-data.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import type { GameSymbol } from "@/app/games.mjs";
import { CONTENTFUL_TAGS } from "@/feature-contentful/constants.mjs";
import type {
  Entry,
  Intro,
  RelatedEntries,
} from "@/feature-contentful/models.mjs";
import {
  EntryModel,
  IntroModel,
  RelatedEntriesModel,
} from "@/feature-contentful/models.mjs";

const PUBLIC_SPACE_ID = "fcn7ii7pun11";
const PUBLIC_TOKEN = "p40rHUpPGuWHMPY1GAgivgIMDrg-ComVkHBBaJxlTOI";
const TARGET_URL = `${appURLs.CONTENTFUL_GQL_ORIGIN}/content/v1/spaces/${PUBLIC_SPACE_ID}/environments/master`;
const HEADERS = {
  Authorization: `Bearer ${PUBLIC_TOKEN}`,
  "Content-Type": "application/json",
};
const GAME_ARTICLES_COLLECTION = (
  type: "id_contains_all" | "id_contains_some" = "id_contains_all",
) => `query($tags: [String!]!, $limit: Int) {
    gameArticleCollection(where: { contentfulMetadata: { tags: { ${type}: $tags }}}, limit: $limit) {
        items {
            title
            excerpt
            ctaText
            contentfulMetadata {
                tags {
                    id
                }
            }
            author {
              name
            }
            sys {
                id
                firstPublishedAt
            }
            coverImage { 
                url 
            }
        }
    }
}`;
const GAME_ARTICLE = `query($entryId: String!) {
    gameArticle(id: $entryId) {
        title
        seoTitle
        seoDescription
        contentfulMetadata {
            tags {
                id
            }
        }
        sys {
            id
            firstPublishedAt
        }
        coverImage {
            url
        }
        author {
            name
        }
        content {
            json 
            links {
                assets {
                    block {
                        sys {
                            id
                        }
                        url
                        contentType
                        width
                        height
                    }
                }
            }
        }
    }
}`;
const INTRO = `query($tags: [String!]!) {
    introCollection(where: { contentfulMetadata: { tags: { id_contains_all: $tags }}}, limit: 1) {
        items {
            title
            description
            backgroundImage {
                url
            }
        }
    }
}`;

export async function getEntriesByTags(
  tags: Array<string>,
  limit?: number,
  type: "id_contains_all" | "id_contains_some" = "id_contains_all",
): Promise<RelatedEntries> {
  if (!Array.isArray(tags) || !tags.length) return [];
  const next = await postData(
    {
      url: TARGET_URL,
      body: JSON.stringify({
        variables: { tags, limit },
        query: GAME_ARTICLES_COLLECTION(type),
      }),
      method: "POST",
    },
    RelatedEntriesModel,
    undefined,
    {
      headers: HEADERS,
    },
  );
  mergeRelatedArticles(next);
  return next;
}

export async function getEntry(entryId: string): Promise<Entry> {
  const next = await postData(
    {
      url: TARGET_URL,
      body: JSON.stringify({
        variables: { entryId },
        query: GAME_ARTICLE,
      }),
      method: "POST",
    },
    EntryModel,
    undefined,
    {
      headers: HEADERS,
    },
  );
  writeState.contentful.entry[entryId] = next;
  return next;
}

export async function getIntro(gameSymbol: GameSymbol): Promise<Intro> {
  const shortName = GAME_SHORT_NAMES[gameSymbol];
  const gameTag = CONTENTFUL_TAGS[shortName];
  if (!gameTag) return;
  const next = await postData(
    {
      url: TARGET_URL,
      body: JSON.stringify({
        variables: { tags: ["intro", gameTag] },
        query: INTRO,
      }),
      method: "POST",
    },
    IntroModel,
    undefined,
    {
      headers: HEADERS,
    },
  );
  if (next) writeState.contentful.intro[shortName] = next;
  return next;
}

function mergeRelatedArticles(next: RelatedEntries) {
  writeState.contentful.related = (() => {
    const map = new Map();
    for (const ref of [next, readState.contentful.related])
      for (const item of ref)
        if (item && !map.has(item.id)) map.set(item.id, item);
    const payload = Array.from(map.values());
    payload[isPersistent] = true;
    return payload;
  })();
}
