import { GAME_SHORT_NAMES, GAMES_COMING_SOON } from "@/app/constants.mjs";
import type { GameSymbol } from "@/app/games.mjs";
import { CONTENTFUL_TAGS } from "@/feature-contentful/constants.mjs";
// import { GAME_SYMBOL_MINECRAFT } from "@/app/upcoming-game-definitions.mjs";
import {
  getEntriesByTags,
  getEntry,
  getIntro,
} from "@/feature-contentful/fetch-contentful.mjs";
import { GAME_SYMBOL_MARVEL_RIVALS } from "@/game-marvel-rivals/definition-symbol.mjs";
import routes from "@/routes/routes.mjs";
import pathRegExp from "@/util/path-regexp.mjs";

// List of games for which Contentful should hijack the homepage
const ALLOW_CONTENTFUL: Array<GameSymbol> = [GAME_SYMBOL_MARVEL_RIVALS];

export function setup() {
  if (!ALLOW_CONTENTFUL.length || !GAMES_COMING_SOON.length) return;
  for (const gameSymbol of ALLOW_CONTENTFUL) {
    const shortName = GAME_SHORT_NAMES[gameSymbol];
    // Homepage injection
    const homepage = routes.find((i) => i.path === `/${shortName}`);
    if (homepage) {
      homepage.fetchData = async () => {
        const gameTag = CONTENTFUL_TAGS[shortName];
        if (!gameTag) return;
        await Promise.all([
          getIntro(gameSymbol),
          getEntriesByTags([gameTag, "topFold"], 1),
          getEntriesByTags([gameTag, "tilesGrid"], 4),
          getEntriesByTags([gameTag, "tilesList"], 4),
        ]);
      };
      homepage.component = () =>
        import("@/feature-contentful/HomepageDefault.jsx");
    }
    // Article injection
    const article = routes.find((i) => i.path === `/${shortName}/article/:id`);
    if (article) {
      article.path = pathRegExp(`/${shortName}/article/:id`);
      article.fetchData = async ([id]) => {
        await getEntry(id).then(() => {
          // Side effect: Get related articles - Doesn't need to be async/await since this UI is at the bottom of the pages
          getEntriesByTags(["map", CONTENTFUL_TAGS[shortName]].filter(Boolean));
        });
      };
      // @ts-ignore
      article.component = () => import("@/feature-contentful/Article.jsx");
    }
    // Map data fetching
    const mapsRoute = routes.find((i) => i.path === `/${shortName}/maps`);
    if (mapsRoute && !mapsRoute.fetchData)
      mapsRoute.fetchData = () =>
        getEntriesByTags(["map", CONTENTFUL_TAGS[shortName]].filter(Boolean));
  }
}

export function teardown() {}
